import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const DermanovaProject = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
        <h1>Dermanova</h1>
    </StyledMainContainer>
  </Layout>
);

DermanovaProject.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DermanovaProject;
